import React from "react";
import { withRouter } from "react-router-dom";

import { Animated } from "react-animated-css";

import logo from '../assets/images/logo.svg';
import mail from '../assets/images/smsnotification.svg';
import logomobile from '../assets/images/logomobile.svg';
import mailmobile from '../assets/images/smsnotificationmobile.svg';
import home from '../assets/images/room 4.png';
import comingSoon from '../assets/images/comingsoon.png';
import button from '../assets/images/button.svg';
import homeMobile from '../assets/images/roommobile.png';
import mailwhiteicon from '../assets/images/mail_white.svg';

function Home() {
    return (
        <div className="bg-lightorange h-screen flex flex-col justify-between md:overflow-hidden">
            <div className="flex justify-between px-2 py-4 md:px-20 md:py-8">
                <img src={logo} alt="BytesAlgo Logo" className="hidden md:block" />
                <img src={logomobile} alt="BytesAlgo Logo" className="block md:hidden" />
                <div className="flex items-center">
                    <img src={mail} alt="Mail" className="mr-1 md:mr-3 hidden md:block" />
                    <img src={mailmobile} alt="Mail" className="mr-1 md:mr-3 block md:hidden" />
                    <p className="text-tiny md:text-base font-normal">connect@bytesalgo.com</p>
                </div>
            </div>
            <div className="md:flex md:justify-between md:pl-20">
                <div className="my-auto">
                    <div class="flex items-center md:justify-start justify-center mb-5">
                        <hr className="w-10 bg-blue h-0.5" />
                        <div class="font-normal text-2xl text-blue mx-5">Coming Soon </div>
                        <hr className="w-10 bg-blue h-0.5" />
                    </div>
                    <h1 className="font-bold 2xl:text-8xl text-6xl text-orange md:text-left text-center mb-10">Nice to <br />meet you</h1>
                    <h6 className="font-normal text-2xl text-darkblue mb-10 max-w-lg md:text-left text-center">We provide IT solutions expertly designed <br/> for the end-user needs</h6>
                    <div className="md:text-left text-center">
                        <button className="bg-darkorange rounded-md px-6 py-3">
                            <a href="mailto:connect@bytesalgo.com">
                                <span className="inline text-white font-black uppercase mr-3">Drop us a hello</span>
                                <img src={mailwhiteicon} alt="mail" className="inline" />
                            </a>
                        </button>
                    </div>
                    <p className="hidden md:block text-small leading-small mt-10 text-darkblue">New website is on the way! hold tight</p>
                </div>
                <img src={home} alt="home" className="hidden md:block 2xl:max-w-max xl:max-w-lg" />
                <img src={homeMobile} alt="home" className="block md:hidden mt-5" />
            </div>
            {/* <div className="hidden md:block px-10 py-0">
                <div className="flex justify-between mb-10 pt-10">
                    <Animated animationIn="bounceInLeft" animationOut="fadeOut" isVisible={true} animationInDuration={1000} animationOutDuration={1000} >
                        <div> 
                            <img src={logo} alt="algo" className="w-full" />
                        </div>
                    </Animated>
                    <Animated animationIn="bounceInRight" animationOut="fadeOut" isVisible={true} animationInDuration={1000} animationOutDuration={1000} >
                        <div>
                            <img src={mail} alt="smsLogo" className="inline-block mr-3" />
                            <p className="inline-block font-objectnormal">connect@bytesalgo.com</p>
                        </div>
                    </Animated>
                </div>
            </div>
            <div className="block md:hidden px-10 py-0">
                <div className="flex justify-between mb-5 pt-8">
                    <Animated animationIn="bounceInLeft" animationOut="fadeOut" isVisible={true} animationInDuration={1000} animationOutDuration={1000} >
                        <div>
                            <img src={logoMobile} alt="algo" />
                        </div>
                    </Animated>
                    <Animated animationIn="bounceInRight" animationOut="fadeOut" isVisible={true} animationInDuration={1000} animationOutDuration={1000} >
                        <div className="ml-5">
                            <img src={mailmobile} alt="smsLogo" className="mr-8 -mb-4" />
                            <p className="font-objectnormal text-extaSmall ml-8">connect@bytesalgo.com</p>
                        </div>
                    </Animated>
                </div>
            </div>
            <div className="hidden md:block">
                <div className="grid md:grid-cols-6">
                    <div className="col-span-2 px-10 py-0 mt-20">
                        <Animated animationIn="bounceIn" animationOut="fadeOut" isVisible={true} animationInDuration={1000} animationOutDuration={1000} >
                            <div>
                                <img src={comingSoon} alt="comingSoon" />
                                <h1 className="text-orange text-big leading-big font-objectbold">Nice to <br /> meet you</h1>
                                <p className="text-small leading-small mt-10 text-darkblue">New website is on the way! hold tight</p>
                                <a href="mailto:connect@bytesalgo.com"><img className="mt-10" src={button} alt="button" /></a>
                            </div>
                        </Animated>
                    </div>
                    <div className="col-span-4">
                        <Animated animationIn="bounceIn" animationOut="fadeOut" isVisible={true} animationInDuration={1000} animationOutDuration={1000} >
                            <div>
                                <img src={home} alt="home" />
                            </div>
                        </Animated>
                    </div>
                </div>
            </div>
            <div className="block md:hidden">
                <div className="">
                    <div className="px-10 py-0">
                        <Animated animationIn="bounceIn" animationOut="fadeOut" isVisible={true} animationInDuration={1000} animationOutDuration={1000} >
                            <div>
                                <img src={comingSoon} alt="comingSoon" className="flex justify-center ml-5" />
                                <h1 className="text-orange text-mid leading-mid text-center mt-5 font-objectbold">Nice to <br /> meet you</h1>
                                <p className="text-midSmall leading-midSmall mt-10 text-center text-darkblue">New website is on the way! hold tight</p>
                                <img className="mt-10 flex justify-center ml-10" src={button} alt="button" />
                            </div>
                        </Animated>
                    </div>
                    <div className="px-0">
                        <Animated animationIn="bounceIn" animationOut="fadeOut" isVisible={true} animationInDuration={1000} animationOutDuration={1000} >
                            <div>
                                <img className="mt-5" src={homeMobile} alt="home" />
                            </div>
                        </Animated>
                    </div>
                </div>
            </div> */}
        </div>
    );
}

export default withRouter(Home);